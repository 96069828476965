import { SvgIcon } from '@material-ui/core';
import React from 'react';

export default function TechEnabledOperationsIcon(props) {
  return (
    <SvgIcon {...props} titleAccess="Tech-Enabled Operations" viewBox="0 0 254 254">
      <path d="M127,0c35.069,0,66.822,14.216,89.803,37.197C239.784,60.178,254,91.931,254,127
		c0,35.07-14.216,66.82-37.197,89.803C193.822,239.784,162.069,254,127,254s-66.819-14.216-89.803-37.197
		C14.216,193.82,0,162.069,0,127s14.216-66.822,37.197-89.803C60.181,14.216,91.93,0,127,0z M66.19,165.088h19.822h3.183
		l-0.012,3.185l-0.275,73.221c11.016,3.663,22.737,5.782,34.908,6.097V102.266v-3.183L127,99.081h64.477
		c0.664-3.79,2.489-7.181,5.098-9.788c3.318-3.319,7.904-5.373,12.971-5.373c5.064,0,9.651,2.054,12.972,5.373
		c3.319,3.319,5.372,7.907,5.372,12.972c0,5.065-2.053,9.652-5.372,12.971s-7.906,5.374-12.972,5.374
		c-5.066,0-9.653-2.055-12.971-5.374c-2.608-2.607-4.434-5.997-5.098-9.787h-61.293v142.142c12.17-0.316,23.89-2.436,34.904-6.099
		v-55.152c-3.79-0.664-7.18-2.49-9.787-5.098c-3.318-3.317-5.373-7.905-5.373-12.971s2.055-9.653,5.373-12.972
		c3.319-3.319,7.906-5.373,12.971-5.373c5.065,0,9.654,2.054,12.973,5.373c3.318,3.318,5.372,7.907,5.372,12.972
		c0,5.065-2.054,9.651-5.372,12.971c-2.607,2.607-5.998,4.434-9.787,5.098v52.835c15.431-6.121,29.301-15.334,40.843-26.876
		c21.829-21.83,35.331-51.989,35.331-85.3c0-33.312-13.502-63.471-35.331-85.3C190.471,19.87,160.312,6.367,127,6.367
		c-33.311,0-63.47,13.502-85.3,35.333C20.569,62.831,7.24,91.769,6.41,123.817h61.25c0.663-3.79,2.49-7.181,5.098-9.788
		c3.318-3.32,7.905-5.373,12.971-5.373c5.064,0,9.652,2.053,12.972,5.373c3.319,3.319,5.371,7.906,5.371,12.972
		s-2.052,9.652-5.371,12.971c-3.319,3.319-7.907,5.373-12.972,5.373c-5.066,0-9.653-2.054-12.971-5.373
		c-2.608-2.607-4.436-5.996-5.098-9.788H6.41C7.24,162.23,20.569,191.168,41.7,212.3c11.549,11.549,25.429,20.767,40.875,26.888
		l0.254-67.73H66.19c-0.664,3.788-2.489,7.178-5.096,9.786c-3.319,3.319-7.907,5.374-12.972,5.374
		c-5.066,0-9.653-2.055-12.971-5.374c-3.319-3.317-5.373-7.905-5.373-12.971s2.054-9.653,5.373-12.972
		c3.319-3.319,7.905-5.373,12.971-5.373c5.064,0,9.653,2.054,12.972,5.373C63.701,157.908,65.526,161.299,66.19,165.088z
		 M77.26,135.47c2.167,2.166,5.162,3.508,8.469,3.508s6.303-1.342,8.47-3.508c2.165-2.166,3.506-5.161,3.506-8.469
		c0-3.308-1.342-6.304-3.506-8.47c-2.167-2.166-5.163-3.508-8.47-3.508c-3.309,0-6.302,1.342-8.469,3.508
		c-2.166,2.166-3.508,5.162-3.508,8.47C73.752,130.307,75.094,133.304,77.26,135.47z M103.797,52.644h46.406
		c0.664-3.791,2.49-7.181,5.098-9.788c3.319-3.32,7.906-5.373,12.971-5.373c5.065,0,9.654,2.053,12.973,5.373
		c3.318,3.318,5.372,7.906,5.372,12.972s-2.054,9.652-5.372,12.971c-3.318,3.318-7.906,5.373-12.973,5.373
		c-5.064,0-9.652-2.055-12.971-5.373c-2.607-2.608-4.434-5.996-5.098-9.788h-46.406c-0.664,3.79-2.489,7.18-5.096,9.788
		c-3.319,3.318-7.907,5.373-12.972,5.373c-5.066,0-9.653-2.055-12.971-5.373c-3.319-3.319-5.375-7.905-5.375-12.971
		s2.056-9.654,5.375-12.972c3.318-3.32,7.905-5.373,12.971-5.373c5.064,0,9.652,2.053,12.972,5.373
		C101.309,45.464,103.134,48.854,103.797,52.644z M201.076,110.734c2.166,2.166,5.161,3.508,8.469,3.508
		c3.307,0,6.303-1.342,8.469-3.508s3.508-5.162,3.508-8.468c0-3.309-1.342-6.304-3.508-8.47c-2.166-2.167-5.162-3.507-8.469-3.507
		c-3.309,0-6.303,1.34-8.469,3.507c-2.166,2.166-3.508,5.161-3.508,8.47C197.568,105.572,198.91,108.568,201.076,110.734z
		 M39.653,176.74c2.166,2.166,5.161,3.508,8.468,3.508s6.303-1.342,8.47-3.508c2.166-2.166,3.506-5.161,3.506-8.468
		c0-3.308-1.34-6.304-3.506-8.47c-2.167-2.166-5.163-3.507-8.47-3.507s-6.302,1.341-8.468,3.507
		c-2.167,2.166-3.508,5.162-3.508,8.47C36.145,171.579,37.487,174.574,39.653,176.74z M159.804,64.297
		c2.166,2.166,5.161,3.508,8.468,3.508c3.308,0,6.305-1.342,8.471-3.508c2.166-2.167,3.506-5.162,3.506-8.469
		c0-3.308-1.34-6.304-3.506-8.47s-5.163-3.508-8.471-3.508c-3.307,0-6.302,1.342-8.468,3.508s-3.508,5.162-3.508,8.47
		C156.296,59.134,157.638,62.13,159.804,64.297z M77.26,64.297c2.167,2.166,5.162,3.508,8.469,3.508s6.303-1.342,8.47-3.508
		c2.165-2.167,3.506-5.162,3.506-8.469c0-3.308-1.342-6.304-3.506-8.47c-2.167-2.166-5.163-3.508-8.47-3.508
		c-3.309,0-6.302,1.342-8.469,3.508c-2.166,2.166-3.508,5.162-3.508,8.47C73.752,59.134,75.094,62.13,77.26,64.297z
		 M176.742,159.803c-2.166-2.166-5.163-3.507-8.471-3.507c-3.307,0-6.302,1.341-8.468,3.507s-3.508,5.162-3.508,8.47
		c0,3.307,1.342,6.302,3.508,8.468s5.161,3.508,8.468,3.508c3.308,0,6.305-1.342,8.471-3.508s3.506-5.161,3.506-8.468
		C180.248,164.965,178.908,161.969,176.742,159.803z" />
    </SvgIcon>
  );
}
